import React, {ReactNode, useState} from "react";
import {Dialog, DialogContent, DialogTitle, IconButton, Link, Typography} from "@mui/material";
import theme from "../../theme";
import Xmark from "../icons/Xmark";
import {useTranslation} from "react-i18next";

export default function PersonalDataProcessingDialog(props: {
    title?: ReactNode
}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false)

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Link
                href="#"
                onClick={() => setOpen(true)}
            >
                {props.title ?? t("PersonalDataProcessingDialog.title")}
            </Link>
            <Dialog
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h4">
                        {t("PersonalDataProcessingDialog.startTitle")}
                    </Typography>
                    <br />
                    <Typography variant="h4">1. Introduction</Typography>
                    <Typography variant="body2">
                        This Privacy Policy (hereinafter referred to as "Privacy Policy") applies to all
                        information that the Administration of the site "Pain Point", located at the domain
                        name painpp.com, may collect about the User during their use of the painpp.com website.
                    </Typography>
                    <br />
    
                    <Typography variant="h4">2. Definition of Terms</Typography>
                    <Typography variant="body2">
                        2.1 The following terms are used in this Privacy Policy:
                    </Typography>
                    <Typography variant="body2">
                        2.1.1 Administration of the site painpp.com (hereinafter referred to as "Site
                        Administration") - authorized employees responsible for managing the site, acting on
                        behalf of "Pain Point", which organizes and/or carries out the processing of personal
                        data, as well as determines the purposes of personal data processing, the composition
                        of personal data subject to processing, and actions (operations) performed with
                        personal data.
                    </Typography>
                    <Typography variant="body2">
                        2.1.2 Personal Data - any information relating to a directly or indirectly identified
                        or identifiable natural person (data subject).
                    </Typography>
                    <Typography variant="body2">
                        2.1.3 Processing of Personal Data - any action (operation) or set of actions performed
                        with or without the use of automated means with personal data, including collection,
                        recording, systematization, accumulation, storage, updating, extraction, use, transfer
                        (distribution, provision, access), depersonalization, blocking, deletion, and
                        destruction of personal data.
                    </Typography>
                    <Typography variant="body2">
                        2.1.4 Confidentiality of Personal Data - a requirement for the Operator or any other
                        person who has access to personal data not to disclose such data without the consent
                        of the data subject or another legal basis.
                    </Typography>
                    <Typography variant="body2">
                        2.1.5 User - a person who has access to the painpp.com website via the Internet and
                        uses the painpp.com website.
                    </Typography>
                    <Typography variant="body2">
                        2.1.6 Cookies - a small piece of data sent by a web server and stored on the User's
                        computer, which a web client or web browser sends to the web server each time it
                        attempts to open a page of the respective website.
                    </Typography>
                    <Typography variant="body2">
                        2.1.7 IP Address - a unique network address of a node in a computer network based on
                        the IP protocol.
                    </Typography>
                    <br />

                    <Typography variant="h4">3. General Provisions</Typography>
                    <Typography variant="body2">3.1 The User's use of the painpp.com website constitutes acceptance of this Privacy Policy and the terms of processing the User's personal data.</Typography>
                    <Typography variant="body2">3.2 If the User does not agree with the terms of this Privacy Policy, the User must cease using the painpp.com website.</Typography>
                    <Typography variant="body2">3.3 This Privacy Policy applies only to the painpp.com website. Pain Point is not responsible for third-party websites that the User may access through links available on the painpp.com website.</Typography>
                    <Typography variant="body2">3.4 The Site Administration does not verify the accuracy of personal data provided by the User.</Typography>
                    <br />

                    <Typography variant="h4">4. Subject of Privacy Policy</Typography>
                    <Typography variant="body2">4.1 This Privacy Policy establishes the obligations of the Site Administration to not disclose and to protect the confidentiality of personal data provided by the User when registering on the painpp.com website or when placing an order for a service.</Typography>
                    <Typography variant="body2">4.2 Personal data allowed to be processed under this Privacy Policy is provided by the User by filling out the registration form on the painpp.com website, and may include:<br/>
                    •	Surname, first name, and middle name;<br/>
                    •	Contact phone number;<br/>
                    •	Email address.</Typography>
                    <Typography variant="body2">4.3 Painpp.com also collects certain data automatically when the User visits the website, including:<br/>
                    •	IP address;<br/>
                    •	Information from cookies;<br/>
                    •	Information about the browser or other program used to access the site;<br/>
                    •	Access time;<br/>
                    •	Address of the page where the ad block is located;<br/>
                    •	Referrer (previous page address).</Typography>
                    <Typography variant="body2">4.4 Disabling cookies may result in the inability to access certain parts of the website that require authorization.</Typography>
                    <Typography variant="body2">4.5 Any other personal information not specified above (e.g., used browsers and operating systems) is subject to secure storage and non-dissemination, except as provided in Sections 6.2 and 6.3 of this Privacy Policy.</Typography>
                    <br />

                    <Typography variant="h4">5. Purposes of Collecting the User's Personal Information</Typography>
                    <Typography variant="body2">5.1 The Site Administration may use the User's personal data for the following purposes:<br/>
                    •	To identify the User registered on the painpp.com website;<br/>
                    •	To provide the User with access to personalized resources of the website;<br/>
                    •	To establish feedback with the User, including sending notifications and processing inquiries and requests;<br/>
                    •	To determine the User's location to ensure security and prevent fraud;<br/>
                    •	To confirm the accuracy of personal data provided by the User;<br/>
                    •	To create an account to make purchases of services if the User has given consent;<br/>
                    •	To notify the User of the status of their order;<br/>
                    •	To process payments and provide support in case of payment disputes;<br/>
                    •	To provide effective customer and technical support;<br/>
                    •	To provide the User, with their consent, with updates, special offers, and other information;<br/>
                    •	To conduct advertising activities with the User's consent;<br/>
                    •	To provide the User with access to partners' websites and services.</Typography>
                    <br />

                    <Typography variant="h4">6. Methods and Terms of Personal Data Processing</Typography>
                    <Typography variant="body2">6.1 The processing of the User's personal data is carried out without time limitation by any lawful means, including in personal data information systems with or without the use of automated means.</Typography>
                    <Typography variant="body2">6.2 The User agrees that the Site Administration has the right to transfer personal data to third parties (e.g., courier services, postal organizations, telecommunication operators) solely for the purpose of fulfilling the User's order.</Typography>
                    <Typography variant="body2">6.3 Personal data of the User may be transferred to authorized state authorities of the United States only on the grounds and in accordance with applicable law.</Typography>
                    <Typography variant="body2">6.4 In case of loss or disclosure of personal data, the Site Administration will inform the User about the loss or disclosure.</Typography>
                    <Typography variant="body2">6.5 The Site Administration takes the necessary organizational and technical measures to protect the User's personal data from unauthorized access, modification, or destruction.</Typography>
                    <br />

                    <Typography variant="h4">7. User Rights</Typography>
                    <Typography variant="body2">7.1 Users have the following rights regarding their personal data:<br/>
                    •	Right to Access: Users may request details of their personal data held by the Site Administration.<br/>
                    •	Right to Correct: Users may request the correction of inaccurate or incomplete personal data.<br/>
                    •	Right to Delete: Users may request the deletion of their personal data, subject to certain exceptions provided by law.<br/>
                    •	Right to Opt-Out: Users may opt out of the sale of their personal data, where applicable.</Typography>
                    <br />

                    <Typography variant="h4">8. Obligations of the Parties</Typography>
                    <Typography variant="body2">8.1 The User must:<br/>
                    •	Provide accurate personal data necessary for the use of the painpp.com website;<br/>
                    •	Update and supplement personal data in case of changes.</Typography>
                    <Typography variant="body2">8.2 The Site Administration must:<br/>
                    •	Use the received information only for the purposes specified in this Privacy Policy;<br/>
                    •	Ensure that personal data remains confidential, and not disclose such information without prior written consent from the User, except as specified in Sections 6.2 and 6.3;<br/>
                    •	Take precautions to protect the confidentiality of the User's personal data.</Typography>
                    <br />

                    <Typography variant="h4">9. Liability of the Parties</Typography>
                    <Typography variant="body2">9.1 The Site Administration will be liable for losses incurred by the User due to unauthorized use of personal data, in accordance with applicable law, except as provided in Sections 6.2, 6.3, and 8.2.</Typography>
                    <Typography variant="body2">9.2 In the event of loss or disclosure of personal data, the Site Administration is not responsible if the information:<br/>
                    •	Became public before its loss or disclosure;<br/>
                    •	Was received from a third party prior to being received by the Site Administration;<br/>
                    •	Was disclosed with the User's consent.</Typography>
                    <br />

                    <Typography variant="h4">10. Dispute Resolution</Typography>
                    <Typography variant="body2">10.1 Before seeking court intervention for disputes arising from the relationship between the User and the Site Administration, it is mandatory to submit a claim for voluntary settlement.</Typography>
                    <Typography variant="body2">10.2 The recipient of the claim must notify the claimant of the results of consideration within 30 calendar days of receiving the claim.</Typography>
                    <Typography variant="body2">10.3 If no agreement is reached, the dispute will be referred to a judicial authority in accordance with applicable law.</Typography>
                    <br />

                    <Typography variant="h4">11. Changes to This Privacy Policy</Typography>
                    <Typography variant="body2">11.1 The Site Administration reserves the right to make changes to this Privacy Policy without the User's consent.</Typography>
                    <Typography variant="body2">11.2 The new Privacy Policy becomes effective upon its posting on the painpp.com website, unless otherwise provided by the new version of the Privacy Policy.</Typography>
                    <Typography variant="body2">11.3 Any questions or suggestions regarding this Privacy Policy should be communicated to painpoint.pp@gmail.com.</Typography>
                    <Typography variant="body2">11.4 The current version of the Privacy Policy is available at http://painpp.com/.</Typography>

                </DialogContent>

            </Dialog>
        </>
    );
}
