import React, {useState} from "react";
import {Box, Button, Dialog, DialogContent, IconButton, Typography} from "@mui/material";
import {styled} from "@mui/system";
import BoxFlex from "../layout/BoxFlex";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import CAvatar from "../data-display/CAvatar";
import Phone from "../icons/Phone";
import Zoom from "../icons/Zoom";
import theme from "../../theme";
import SendBackward from "../icons/SendBackward";
import {dateFormatter, isDev, priceFormatter} from "../../utils";
import {communicationMethods} from "../../types/communicationMethod";
import api from "../../api/api";
import {Moment} from "moment";
import AppointmentInputs from "../inputs/AppointmentInputs";
import {useError} from "../../context/ErrorHandler";
import {useTranslation} from "react-i18next";

const Wrapper = styled(BoxFlexColumn)({
    borderRadius: 30,
    width: 860,
    overflow: "hidden",

    "@media (max-width: 899px)": {
        width: "auto",
    },
}) as typeof BoxFlexColumn

const Header = styled(BoxFlex)(({theme}) => ({
    backgroundColor: theme.palette.secondary.main,
    justifyContent: "space-between",
    padding: 28,

    "@media (max-width: 899px)": {
        padding: "20px 14px",
    },
})) as typeof BoxFlex

const Content = styled(BoxFlexColumn)(({theme}) => ({
    padding: "28px 28px 30px 28px",
    background: theme.palette.white.main,
    gridGap: 28,

    "@media (max-width: 899px)": {
        gridGap: 14,
        padding: "24px 16px 16px 16px",
        textAlign: "center",
    },
})) as typeof BoxFlexColumn

const MainInfoBlock = styled(BoxFlex)({
    gridGap: 24,
    alignItems: "center",

    "@media (max-width: 899px)": {
        flexDirection: "column",
    },
}) as typeof BoxFlex

const CommunicationBlock = styled(BoxFlex)(({theme}) => ({
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,

    "@media (max-width: 899px)": {
        flexDirection: "column",
    },
})) as typeof BoxFlex

const BottomNavigation = styled(BoxFlex)({
    alignItems: "center",
    justifyContent: "space-between",

    "@media (max-width: 899px)": {
        flexDirection: "column",
    },
}) as typeof BoxFlex

const PhoneNumberContainer = styled(Box)(({theme}) => ({
    padding: "20px 70px 20px 0",
    borderRight: `1px solid ${theme.palette.border.main}`,
    flexShrink: 0,

    "@media (max-width: 899px)": {
        borderRight: "none",
        borderBottom: `1px solid ${theme.palette.border.main}`,
        padding: "16px 0",
    },
})) as typeof Box

const CommunicationMethodBlockContainer = styled(BoxFlex)({
    width: "100%",
    paddingLeft: 20,
    alignItems: "center",
    justifyContent: "space-between",

    "& a h6": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 300,
    },

    "@media (max-width: 899px)": {
        padding: "8px 0",

        "& a h6": {
            maxWidth: 186,
        }
    },
}) as typeof BoxFlex

const PriceTypography = styled(Typography)({
    "@media (max-width: 899px)": {
        width: "100%",
        paddingBottom: 14,
        borderBottom: `1px solid ${theme.palette.border.main}`,
    },
}) as typeof Typography

const CommunicationMethodBlock = (props: {
    communicationMethod: keyof typeof communicationMethods,
    link?: string,
}) => {
    const { t } = useTranslation();
    return (
        <CommunicationMethodBlockContainer>
            {props.communicationMethod === communicationMethods.zoom
                ? (
                    <>
                        {props.link
                            ? <>
                                <a
                                    href={props.link}
                                >
                                    <BoxFlex
                                        sx={{
                                            gridGap: 16,
                                        }}
                                    >
                                        <Zoom
                                            color={theme.palette.text.tertiary}
                                        />

                                        <Typography
                                            variant="subtitle2"
                                        >
                                            {props.link}
                                        </Typography>
                                    </BoxFlex>
                                </a>

                                <IconButton
                                    onClick={() => navigator.clipboard.writeText(props.link as string)}
                                >
                                    <SendBackward
                                        color={theme.palette.primary.main}
                                    />
                                </IconButton>
                            </>
                            : <Typography
                                variant="subtitle2"
                            >
                                {t("RecordingsBlock.noZoomMsg")}
                            </Typography>
                        }
                    </>
                )
                : <Typography
                    variant="subtitle2"
                >
                    {t("RecordingsBlock.phoneNumberMsg")}
                </Typography>
            }
        </CommunicationMethodBlockContainer>
    )
}

const RecordingCard = (props: {
    id: number,
    dateTs: number,
    time: string,
    specialistId: number,
    specialistName: string,
    specialistPosition: string,
    specialistPrice: number,
    communicationMethod: keyof typeof communicationMethods,
    isPossibleCancel: boolean,
    specialistPhone?: string,
    meetingLink?: string,
    specialistAvatar?: string,
    description?: string,
    paymentLink?: string,
    fetchDataCb: () => void,
}) => {
    const { t } = useTranslation();
    const { setError } = useError();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false)
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [changeAppointmentDialog, setChangeAppointmentDialog] = useState<boolean>(false)
    const [disabledBtns, setDisabledBtns] = useState<boolean>(false)
    const [keyResetInputs, setKeyResetInputs] = useState<number>(0);
    const [date, setDate] = useState<Moment | null>(null);
    const [time, setTime] = useState<Moment | null>(null)

    const handleCancel = async () => {
        setDisabledBtns(true)
        if (!isDev) {
            await api.delete(`/appointments/${props.id}`)
                .then(res => {
                    if (res.status === 200) {
                        setOpenConfirmDialog(false)
                        setOpenDialog(true)
                        props.fetchDataCb()
                    }
                })
                .catch(err => setError(err.response.data.message))
        }
        setDisabledBtns(false)
    }

    const handleChangeAppointment = async () => {
        setDisabledBtns(true)
        if (!isDev) {
            await api.patch(`/appointments/${props.id}`, {
                date: date?.format('Y-MM-DD'),
                time: time?.format('HH:mm'),
            })
                .then(res => {
                    if (res.status === 200) {
                        props.fetchDataCb()
                        setChangeAppointmentDialog(false)
                        setKeyResetInputs(prevState => {
                            prevState++
                            return prevState
                        })
                    }
                })
                .catch(err => setError(err.response.data.message))

        }
        setDisabledBtns(false)
    }

    return (
        <>

            <Dialog
                onClose={() => setChangeAppointmentDialog(false)}
                open={changeAppointmentDialog}
            >
                <DialogContent
                    sx={{
                        padding: "38px 47px",
                    }}
                >
                    <Typography
                        variant="h4"
                    >
                        {t("RecordingsBlock.rescheduleAppointment")}
                    </Typography>
                    <BoxFlexColumn
                        sx={{
                            marginTop: "36px",
                        }}
                    >

                        <AppointmentInputs
                            key={keyResetInputs}
                            specialistId={props.specialistId}
                            onChangeDate={setDate}
                            onChangeTime={setTime}
                        />

                        <BoxFlex
                            sx={{
                                flexWrap: "wrap",
                                gridGap: "18px",
                                marginTop: "36px",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={disabledBtns}
                                onClick={() => setChangeAppointmentDialog(false)}
                            >
                                {t("common.cancel")}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={disabledBtns}
                                onClick={handleChangeAppointment}
                            >
                                {t("common.confirm")}
                            </Button>
                        </BoxFlex>
                    </BoxFlexColumn>
                </DialogContent>
            </Dialog>
            <Dialog
                onClose={() => setOpenConfirmDialog(false)}
                open={openConfirmDialog}
            >
                <DialogContent
                    sx={{
                        padding: "38px 47px",
                    }}
                >
                    <Typography
                        variant="h4"
                    >
                        {t("RecordingsBlock.cancelAppointmentTitle")}
                    </Typography>
                    <BoxFlex
                        sx={{
                            flexWrap: "wrap",
                            gridGap: "18px",
                            marginTop: "36px",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            disabled={disabledBtns}
                            onClick={() => setOpenConfirmDialog(false)}
                        >
                            {t("common.no")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={disabledBtns}
                            onClick={handleCancel}
                        >
                            {t("common.yes")}
                        </Button>
                    </BoxFlex>
                </DialogContent>
            </Dialog>
            <Dialog
                onClose={() => setOpenDialog(false)}
                open={openDialog}
            >
                <DialogContent
                    sx={{
                        padding: "38px 47px",
                    }}
                >
                    <Typography
                        variant="h4"
                        align="center"
                    >
                        {t("RecordingsBlock.successCancelAppointmentTitle")}
                    </Typography>
                    <br/>
                    <Typography
                        variant="h4"
                        align="center"
                    >
                        {t("RecordingsBlock.successCancelAppointmentSubtitle")}
                    </Typography>
                </DialogContent>
            </Dialog>
            <Wrapper>
                <Header>
                    <Typography
                        variant="subtitle1"
                    >
                        {dateFormatter(props.dateTs * 1000)}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                    >
                        {props.time}
                    </Typography>
                </Header>
                <Content>
                    <MainInfoBlock>
                        <CAvatar
                            src={props.specialistAvatar ?? undefined}
                            size="lg"
                        />

                        <BoxFlexColumn
                            sx={{
                                gridGap: 16,
                            }}
                        >
                            <Typography
                                variant="h4"
                            >
                                {props.specialistName}
                            </Typography>
                            <Typography
                                variant="captionUppercase"
                                color="text.tertiary"
                            >
                                {props.specialistPosition}
                            </Typography>
                        </BoxFlexColumn>
                    </MainInfoBlock>

                    <Typography
                        variant="body2"
                    >
                        {
                            props.description
                            || t("RecordingsBlock.appointmentDescription")
                        }
                    </Typography>
                    <CommunicationBlock>
                        {props.specialistPhone &&
                            <PhoneNumberContainer>
                                <a
                                    href={`tel:${props.specialistPhone}`}
                                >
                                    <BoxFlex
                                        sx={{
                                            gridGap: 16,
                                        }}
                                    >
                                        <Phone
                                            color={theme.palette.text.tertiary}
                                        />

                                        <Typography
                                            variant="subtitle2"
                                            color="text.primary"
                                        >
                                            {props.specialistPhone}
                                        </Typography>
                                    </BoxFlex>
                                </a>
                            </PhoneNumberContainer>
                        }

                        <CommunicationMethodBlock
                            communicationMethod={props.communicationMethod}
                            link={props.meetingLink}
                        />
                    </CommunicationBlock>
                    <BottomNavigation>
                        <PriceTypography
                            variant="subtitle1"
                        >
                            {priceFormatter(props.specialistPrice)}/h
                        </PriceTypography>

                        <BoxFlex>
                            {props.isPossibleCancel &&
                                <Button
                                    variant="text"
                                    onClick={() => setOpenConfirmDialog(true)}
                                    disabled={disabledBtns}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        color="text.tertiary"
                                    >
                                        {t("common.cancel")}
                                    </Typography>
                                </Button>
                            }
                            <Button
                                variant="text"
                                disabled={disabledBtns}
                                onClick={() => setChangeAppointmentDialog(true)}
                            >
                                <Typography
                                    variant="subtitle2"
                                    color="text.tertiary"
                                >
                                    {t("RecordingsBlock.reschedule")}
                                </Typography>
                            </Button>
                            {props.paymentLink &&
                                <Button
                                    variant="text"
                                    onClick={() => window.location.replace(props.paymentLink ?? "")}
                                    disabled={disabledBtns}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        color="text.tertiary"
                                    >
                                        {t("common.pay")}
                                    </Typography>
                                </Button>
                            }
                        </BoxFlex>
                    </BottomNavigation>
                </Content>
            </Wrapper>
        </>
    )
};

export default RecordingCard;