import React, {useEffect, useState} from "react";
import {Button, Container, Link, Skeleton, Typography, useMediaQuery} from "@mui/material";
import routes from "../../routes";
import SpecialistCard from "../surfaces/SpecialistCard";
import Carousel from "react-multi-carousel";
import {isDev, sleep} from "../../utils";
import api from "../../api/api";
import BoxFlex from "../layout/BoxFlex";
import CIconButton from "../inputs/CIconButton";
import ArrowSmallLeft from "../icons/ArrowSmallLeft";
import ArrowSmallRight from "../icons/ArrowSmallRight";
import BoxFlexColumn from "../layout/BoxFlexColumn";
import {useTranslation} from "react-i18next";

const ButtonGroup = (props: {
    next?: any,
    previous?: any,
}) => {
    const { t } = useTranslation();
    const matches = useMediaQuery('(min-width:900px)');

    return (
        <Typography
            variant="h2"
            className="block-title"
            sx={{
                order: -1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            {t("Specialists.leadingTitle")}

            {matches ? (
                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    component={Link}
                    href={routes.specialists}
                    sx={{
                        display: {
                            xs: "none",
                            sm: "block",
                        },
                    }}
                >
                    {t("Specialists.allTitle")}
                </Button>
            ) : (
                <BoxFlex
                    sx={{
                        gridGap: "16px",
                    }}
                >
                    <CIconButton
                        variant="outlined"
                        onClick={() => props.previous()}
                    >
                        <ArrowSmallLeft/>
                    </CIconButton>
                    <CIconButton
                        variant="outlined"
                        onClick={() => props.next()}
                    >
                        <ArrowSmallRight/>
                    </CIconButton>
                </BoxFlex>
            )}
        </Typography>
    )
}

const SpecialistsBlock = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{
        id: number,
        name: string,
        position: string,
        price: number,
        shortDescription?: string,
        avatar?: string,
    }[]>([])

    const fetchData = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../../mocks/specialists__getAllLeading.json")
            setData(response)
        } else {
            await api.get(`/specialists/leading`)
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                    }
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container maxWidth="xl">
            {loading &&
                <BoxFlex sx={{
                    gridGap: "1rem",
                }}>
                    <Skeleton width="100%" height="250px"/>
                    <Skeleton width="100%" height="250px"/>
                    <Skeleton width="100%" height="250px"/>
                </BoxFlex>
            }
            <BoxFlexColumn>
                <Carousel
                    responsive={{
                        tablet: {
                            breakpoint: {max: 999999, min: 464},
                            items: 3
                        },
                        mobile: {
                            breakpoint: {max: 600, min: 0},
                            items: 1
                        },
                    }}
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroup/>}
                    infinite={true}
                    itemClass="carousel-item-specialist"
                >
                    {loading && <>
                        <Skeleton height="250px"/>
                        <Skeleton height="250px"/>
                        <Skeleton height="250px"/>
                    </>
                    }
                    {data.map((datum, i) => (
                        <SpecialistCard
                            key={i}
                            id={datum.id}
                            name={datum.name}
                            position={datum.position}
                            shortDescription={datum.shortDescription}
                            price={datum.price}
                            avatar={datum.avatar}
                        />
                    ))}
                </Carousel>
            </BoxFlexColumn>
        </Container>
    );
}

export default SpecialistsBlock;