import React, {useEffect, useState} from "react";
import {Box, Container, Skeleton, Typography} from "@mui/material";
import {styled} from "@mui/system";
import partners01 from "../../img/partners-01.png";
import partners02 from "../../img/partners-02.png";
import partners03 from "../../img/partners-03.png";
import CPaper from "../surfaces/CPaper";
import theme from "../../theme";
import BoxFlex from "../layout/BoxFlex";
import {isDev, sleep} from "../../utils";
import api from "../../api/api";
import {useTranslation} from "react-i18next";

const OurPartnersContainer = styled(BoxFlex)({
    gridGap: 20,

    "@media (max-width: 899px)": {
        flexDirection: "column",
        gridGap: 12,
    },
}) as typeof Box;

const OurPartnersItem = styled(BoxFlex)({
    height: 160,
    alignItems: "center",
    justifyContent: "center",

    "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
    }
}) as typeof BoxFlex;

const StyledCPaper = styled(CPaper)({
    backgroundColor: theme.palette.surface.main,
    flex: "1 1 0",
    width: 0,

    "@media (max-width: 899px)": {
        width: "auto",
        flex: "initial",
    },
}) as typeof CPaper;


const OurPartnersBlock = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ image: string, link: string }[]>([])

    const fetchData = async () => {
        if (isDev) {
            await sleep(500)
            setData([
                {
                    image: partners01,
                    link: "#",
                },
                {
                    image: partners02,
                    link: "#",
                },
                {
                    image: partners03,
                    link: "#",
                }
            ]);
        } else {
            await api.get('/partners')
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                    }
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {(loading || (!loading && data.length > 0)) &&
                <Container maxWidth="xl">
                    <Typography
                        variant="h2"
                        className="block-title"
                        sx={{
                            marginBottom: "25px",
                        }}
                    >
                        {t("OurPartners.title")}
                    </Typography>

                    <OurPartnersContainer>
                        {loading &&
                            <>
                                <StyledCPaper>
                                    <Skeleton
                                        variant="rounded"
                                        height="160px"
                                    />
                                </StyledCPaper>
                                <StyledCPaper>
                                    <Skeleton
                                        variant="rounded"
                                        height="160px"
                                    />
                                </StyledCPaper>
                                <StyledCPaper>
                                    <Skeleton
                                        variant="rounded"
                                        height="160px"
                                    />
                                </StyledCPaper>
                            </>
                        }
                        {data.map((datum, i) => (
                            <StyledCPaper
                                key={i}
                            >
                                <a
                                    href={datum.link ?? "#"}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <OurPartnersItem>
                                        <img
                                            src={datum.image}
                                            alt={t("OurPartners.partnerImgAlt")}
                                        />
                                    </OurPartnersItem>
                                </a>
                            </StyledCPaper>
                        ))}
                    </OurPartnersContainer>
                </Container>
            }
        </>
    );
}

export default OurPartnersBlock;
