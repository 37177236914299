import React, {ReactNode, useState} from "react";
import {Dialog, DialogContent, DialogTitle, IconButton, Link, Typography} from "@mui/material";
import theme from "../../theme";
import Xmark from "../icons/Xmark";
import {Trans, useTranslation} from "react-i18next";

export default function OfferDialog(props: {
    title: ReactNode
}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false)

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Link
                href="#"
                onClick={() => setOpen(true)}
            >
                {props.title}
            </Link>
            <Dialog
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h4" align="center">
                        {t("OfferDialog.offerTitle")}
                    </Typography>

                    <Typography variant="body2">
                        Настоящий договор является официальным предложением (публичной офертой) адресованным любому физическому лицу, в дальнейшем именуемому Заказчик, согласившемуся с условиями настоящей публичной оферты, путем ее полного и безоговорочного акцепта.
Заказчик обязан полностью ознакомиться с настоящим документом до момента внесения оплаты.
Общество с ограниченной ответственностью "Без Боли" (сокращенное наименование ООО «ББ»), именуемое в дальнейшем «Исполнитель», в лице директора Скубилова Александра Юрьевича, действующего на основании Устава, именуемое в дальнейшем Исполнитель, выражает намерение заключить договор об оказании платных информационно-консультационных услуг (далее – «Услуг») с Заказчиком на условиях настоящей оферты (далее - «Договор»).
                    </Typography>
                    <br/>
                    <Typography variant="h4">
                        1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                        1.1. В целях настоящей оферты нижеприведенные термины используются в следующих значениях:
Оферта — настоящий документ опубликованный на сайте https://ppain.ru/
Акцепт Оферты — полное и безоговорочное принятие Оферты путем осуществления действий, указанных в п. 2.2. Оферты. Акцепт Оферты создает Договор.
Заказчик — совершеннолетнее лицо, осуществившее Акцепт Оферты, являющееся потребителем платных Услуг по заключенному Договору.
Договор — договор между Заказчиком и Исполнителем на предоставление Услуг, который заключается посредством Акцепта Оферты.
Сайт — интернет ресурс, содержащий полную информацию о содержании и стоимости услуг размещенный по адресу https://ppain.ru/
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    2. ПРЕДМЕТ ДОГОВОРА
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    2.1. Исполнитель предоставляет, а Заказчик оплачивает информационно-консультационные услуги в виде получения доступа к онлайн-консультации (далее услуги), определенным Приложениями к Договору, либо размещенными в соответствующих разделах Сайта.
                    </Typography>
                    <Typography variant="body2">
2.2. Оплатой Заказчик выражает свое полное и безоговорочное принятие условий настоящего договора (акцепт).
                    </Typography>
                    <Typography variant="body2">
2.3. Стоимость услуг по Договору сообщается на Сайте и составляет сумму, указанную для конкретных услуг, интересующих Заказчика, в соответствии с прейскурантом, указанным на Сайте.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    3. ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    3.1. Исполнитель обязан:
                    </Typography>
                    <Typography variant="body2">
3.1.1. Организовать и обеспечить надлежащее качественное исполнение услуг, предусмотренных в п. 2.1. настоящего Договора, в соответствии с объемом услуг, выбранным Заказчиком.
                    </Typography>
                    <Typography variant="body2">
3.1.2. Для организации доступа к материалам для оказания услуг и трансляциям Исполнитель обязуется провести регистрацию Заказчика в учётной системе Сайта.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    4. ОБЯЗАННОСТИ ЗАКАЗЧИКА
                    </Typography>
                    <br/>
                    <Typography variant="body2">
4.1. Заказчик предоставляет достоверную информацию при регистрации на Сайте и поддерживает эту информацию в актуальном состоянии при ее изменении.
                    </Typography>
                    <Typography variant="body2">
4.2. Исполнитель имеет право отказать Заказчику в оказании услуг в случае предоставления Заказчиком заведомо неверной (ложной) информации.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    5. ПРАВА ИСПОЛНИТЕЛЯ И ЗАКАЗЧИКА
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    5.1. Исполнитель вправе для оказания услуг привлекать третьих лиц, отвечая за их действия.
                    </Typography>
                    <Typography variant="body2">
5.2. Исполнитель вправе отказать Заказчику в оказании новых услуг, если Заказчик в период получения услуг допускал нарушения, предусмотренные гражданским законодательством и настоящим договором, и дающие Исполнителю право в одностороннем порядке отказаться от исполнения договора.
                    </Typography>
                    <Typography variant="body2">
5.3. Заказчик вправе требовать от Исполнителя предоставления информации по вопросам, касающимся организации и обеспечения надлежащего исполнения услуг, предусмотренных разделом 2 настоящего договора.
                    </Typography>
                    <Typography variant="body2">
5.4. Исполнитель имеет право незамедлительно прекратить оказание услуг с возвращением денежных средств за оплаченные, но не оказанные услуги, в случае проявления со стороны Заказчика агрессии или неуважительного отношения.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    6. ОПЛАТА УСЛУГ И ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
6.1. Заказчик оплачивает услуги, указанные в разделе 2.1 Договора в российских рублях. Оплата предоставляемых услуг производится на основании счета на оплату.
                    </Typography>
                    <Typography variant="body2">
6.2. Заказчик производит оплату услуг Исполнителя в размере 100% аванса путем перечисления безналичных денежных средств Исполнителю на расчетный счет Исполнителя.
                    </Typography>
                    <Typography variant="body2">
6.3. Моментом оплаты считается поступление средств на расчетный счет Исполнителя.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    7. СОБЛЮДЕНИЕ АВТОРСКОГО ПРАВА И КОНФИДЕНЦИАЛЬНОСТЬ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
7.1. Заказчик обязан соблюдать авторские права Исполнителя на все материалы полученные в рамках оказания услуг в соответствии с Законом об авторских правах.
                    </Typography>
                    <Typography variant="body2">
7.2. Заказчику запрещено использовать полученные в рамках оказания услуг Исполнителя материалы в коммерческих целях, от своего имени, размещать в открытом доступе, передавать третьим лицам.
                    </Typography>
                    <Typography variant="body2">
7.3. Заказчику запрещено передавать третьим лицам логин и пароль доступа на Сайт. Обнаружение факта доступа к материалам третьих лиц является основанием для одностороннего расторжения Исполнителем договора без возврата денежных средств.
                    </Typography>
                    <Typography variant="body2">
7.4. Факт заключения настоящего Договора не рассматривается Сторонами как конфиденциальная информация.
                    </Typography>
                    <Typography variant="body2">
7.5. Стороны обязуются не разглашать информацию, полученную Сторонами в ходе выполнения своих обязательств по настоящему Договору, за исключением случаев, когда Сторона обязана предоставить такую информацию в соответствии с действующим законодательством, применимым к Договору, или было получено согласие на разглашение такой информации.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    8. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ ЗАКАЗЧИКА
                    </Typography>
                    <br/>
                    <Typography variant="body2">
8.1. Персональные данные Заказчика обрабатываются в соответствии с Федеральным Законом «О персональных данных» № 152-ФЗ.
                    </Typography>
                    <Typography variant="body2">
8.2. При регистрации на Сайте Заказчик предоставляет следующую информацию: ФИО, контактный номер телефона, адрес электронной почты.
                    </Typography>
                    <Typography variant="body2">
8.3. Предоставляя свои персональные данные Исполнителю, Заказчик соглашается на их обработку Исполнителем, в том числе в целях выполнения Исполнителем обязательств перед Заказчиком в рамках настоящей публичной оферты, продвижения Исполнителем товаров и услуг, проведения электронных и sms опросов, клиентской поддержки, контроля удовлетворенности Заказчика, а также качества услуг, оказываемых Исполнителем.
                    </Typography>
                    <Typography variant="body2">
8.4. Под обработкой персональных данных понимается любое действие (операция) или совокупность действий (операций), совершаемых Исполнителем с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение) извлечение, использование, обезличивание, блокирование, удаление, уничтожение персональных данных.
                    </Typography>
                    <Typography variant="body2">
8.5. Исполнитель вправе использовать технологию «cookies». «Cookies» не содержат конфиденциальную информацию. Заказчик настоящим дает согласие на сбор, анализ и использование cookies, в том числе третьими лицами для целей формирования статистики и оптимизации рекламных сообщений.
                    </Typography>
                    <Typography variant="body2">
8.6. Исполнитель не несет ответственности за сведения, предоставленные Заказчиком на Сайте в общедоступной форме.
                    </Typography>
                    <Typography variant="body2">
8.7. Исполнитель вправе осуществлять записи телефонных разговоров с Заказчиком. При этом Исполнитель обязуется: предотвращать попытки несанкционированного доступа к информации, полученной в ходе телефонных переговоров, и/или передачу ее третьим лицам, не имеющим непосредственного отношения к исполнению Заказов, в соответствии с п. 4 ст. 16 Федерального закона «Об информации, информационных технологиях и о защите информации».
                    </Typography>
                    <br/>

                    <Typography variant="h4">
9. ОСНОВАНИЯ И УСЛОВИЯ ИЗМЕНЕНИЯ И РАСТОРЖЕНИЯ ДОГОВОРА
                    </Typography>
                    <br/>
                    <Typography variant="body2">
9.1. Условия, на которых заключен настоящий договор, могут быть изменены либо по соглашению сторон, либо в соответствии с действующим законодательством Российской Федерации.
                    </Typography>
                    <Typography variant="body2">
9.2. Настоящий договор может быть расторгнут по соглашению сторон. По инициативе одной из сторон договор может быть расторгнут по основаниям, предусмотренным действующим законодательством Российской Федерации. В случае расторжения договора до начала оказания услуг, Исполнитель возвращает Заказчику оплату.
                    </Typography>
                    <Typography variant="body2">
9.3. Отказ от получения услуг возможен не менее чем за 24 часа до начала момента оказания услуг. При отказе от получения услуг, денежные средства возвращаются только если Заказчик так и не приступил к получению услуги.
                    </Typography>
                    <Typography variant="body2">
9.4. Возврат осуществляется в течение десяти рабочих дней с момента расторжения Договора.
                    </Typography>
                    <Typography variant="body2">
9.5. Исполнитель вправе отказаться от исполнения договора, если Заказчик нарушил п.8.3 и 8.4 услуг по настоящему договору.
                    </Typography>
                    <Typography variant="body2">
9.6. Договор считается расторгнутым со дня письменного уведомления Исполнителем Заказчика об отказе от исполнения договора.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    10. ОТВЕТСТВЕННОСТЬ ЗА НЕИСПОЛНЕНИЕ ИЛИ НЕНАДЛЕЖАЩЕЕ ИСПОЛНЕНИЕ ОБЯЗАТЕЛЬСТВ ПО НАСТОЯЩЕМУ ДОГОВОРУ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
10.1. В случае неисполнения или ненадлежащего исполнения сторонами обязательств по настоящему договору они несут ответственность, предусмотренную гражданским законодательством и законодательством о защите прав потребителей, на условиях, установленных этим законодательством.
                    </Typography>
                    <Typography variant="body2">
10.2. Заказчик соглашается и признаёт, что действия, совершённые с использованием аутентификационных данных при получении онлайн информационно-консультационных услуг, порождают юридические последствия, аналогичные использованию личных подписей.
                    </Typography>
                    <Typography variant="body2">
10.3. Исполнитель не гарантирует отсутствие неполадок и ошибок в работе Сайта и сервисов.
                    </Typography>
                    <Typography variant="body2">
10.4. Ни одна из Сторон не будет нести ответственности за полное или частичное неисполнение любой из своих обязанностей по настоящему Договору, если неисполнение будет являться следствием обстоятельств непреодолимой силы. Сторона, для которой создалась невозможность исполнения обстоятельств, обязана немедленно в письменном виде уведомить другую сторону о наступлении, предполагаемом сроке действия и прекращения вышеуказанных обстоятельств. Неуведомление или несвоевременное уведомление лишает Стороны права ссылаться на любое вышеуказанное обстоятельство как на основание, освобождающее от ответственности за неисполнение обязательства.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    11. СРОК ДЕЙСТВИЯ ДОГОВОРА И ДРУГИЕ УСЛОВИЯ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
11.1. Настоящий Договор вступает в силу с момента акцепта Заказчиком данной Публичной оферты и действует до полного исполнения Сторонами взятых на себя обязательств.
                    </Typography>
                    <Typography variant="body2">
11.2. Исполнитель оставляет за собой право внести изменения в настоящий Договор в любой момент по своему усмотрению, такие изменения вступают в силу с момента размещения изменённого текста Договора, если иной срок вступления изменений в силу не определён дополнительно в тексте публикации.
                    </Typography>
                    <Typography variant="body2">
11.3. Стороны считают получение информационно-консультационной услуги выполненной, если Заказчику был предоставлен доступ к онлайн консультации и не поступило претензий в течение 2 рабочих дней после окончания оказания услуги.
                    </Typography>
                    <br/>
                    <Typography variant="h4">
                    Описание информационно-консультационной услуги
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    «Мнение специалиста» — это вид Информационной услуги.
ООО "Без Боли" посредством сайта https://ppain.ru/ не оказывает медицинские услуги и не является медицинской организацией.
Определение диагноза и выбор методики лечения должны осуществляться только вашим лечащим врачом.
ООО "Без Боли" не несет ответственности за возможные отрицательные последствия, возникшие в результате использования информации, размещенной на сайте https://ppain.ru/. Размещенная информация на сайте https://ppain.ru/. предоставляется исключительно в информационных целях и не является медицинскими рекомендациями.
«Мнение специалиста» — это Информационная услуга дистанционного консультирования врачом для формирования пациентом представления о диагнозе и направлении диагностического поиска.
Что значит «информационная услуга»?
Это означает, что услуга не является медицинской.
Мы не можем:
    • ставить диагнозы и лечить через интернет;
    • выдать заключение;
    • выписать электронный рецепт;
    • учесть оплату за счет депозита;
    • предоставить справку для получения налогового вычета.
Мы можем:
    • обсудить клиническую ситуацию;
    • обсудить результаты исследований;
    • дать исчерпывающую информацию о подобных заболеваниях и синдромах;
    • обсудить, какие исследования обычно используют для постановки диагноза, и дать им оценку;
    • помочь вам и вашему очному врачу сориентироваться в дальнейшей тактике ведения, обсудить рекомендации по данной болезни, выпущенные ассоциациями разных стран;
    • суммировать свое мнение в виде прикрепленного в личном кабинете отчета о консультации - это резюме обсуждения, которое подводит итог общения с врачом, но не имеет силы медицинского документа.

Как оказывается информационная услуга «Мнение специалиста»?
Подробная последовательность действий:
    1. Подготовьте свои вопросы.
    2. Выберите подходящего специалиста, выберите дату, время и способ связи.
    3. Оплатите услугу онлайн.
    4. В личном кабинете по адресу https://ppain.ru/profile будет указана дата, время и способ связи со специалистом, эта же информация продублируется вам по способу связи, который вы выбрали ранее.
    5. Направьте значимую для вас информацию, по поводу которой хотите получить консультацию - результаты МРТ, КТ и анализов (если таковая имеется и предполагается связь с болевым синдромом) — на почту painpoint.pp@gmail.com. В теме письма укажите время консультации и фамилию специалиста.
    6. Специалист свяжется с вами в указанное время самостоятельно.
    7. После консультации, в вашем личном кабинете будет прикреплен фаил с текстовым описанием и выводами по вашей консультации.
    8. Отмена назначенной консультации и возврат денежных средств возможен, при отмене записи в личном кабинете https://ppain.ru/profile за 24ч до назначенной консультации.
    9. Перенос консультации возможен за 6ч, до ранее выбранной консультации.
    10. Время консультации 30 минут.
                    </Typography>

                </DialogContent>
            </Dialog>
        </>
    );
}